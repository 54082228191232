.subMenu {
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 11;
	max-width: 230px;
	width: 0;
	height: 100vh;
	min-height: 100vh;
	max-height: 100vh;
	left: 66px;
	top: 0;
	margin: 0;
	padding: 0;
	background-color: var(--boxColor);
	transition: all 0.10s;
}
.subMenu.show {
	width: 230px;
}

.subMenu .title {
	margin: 22px 0 18px 0;
	padding: 0 15px 6px 15px;
	font-size: 16pt;
	text-align: left;
	color: var(--textColor);
}

.subMenu nav a {
	text-decoration: none;
	color: inherit;
}
