@media (prefers-color-scheme: light) {
	:root {
		--mainColor: #008C4F;
		--mainColorAlpha: rgba(0, 140, 79, 0.5);
		--fontSizeInputText: 10pt;
		--borderRadiusInputText: 3px;
		--paddingInputText: 0 15px;
		--backgroundColor: #FFFFFF;
		--boxColor: #EBEBEB;
		--logo: url(../image/logo/hivy-logo-black.svg);
		--textColor: black;
		--errorColor: rgb(195, 48, 48);
		--warningColor: rgb(195, 134, 19);
		--validColor: var(--mainColor);

		--backgroundColorOptions: var(--backgroundColor);
		--borderColorOptions: 1px solid #D2D2D2;
		--boxShadowOptions: 0 3px 6px rgba(0, 0, 0, 0.2);
		--textColorOptionItems: var(--textColor);
		--backgroundColorOptionItemsHover: var(--boxColor);
		--textColorOptionItemsRed: rgb(195, 48, 48);
		--backgroundColorOptionItemsRed: rgba(195, 48, 48, 0.1);

		--backgroundColorMenu: var(--boxColor);
		--borderMenu: 1px solid #D2D2D2;
		--borderItemMenuSelected: 3px solid var(--mainColor);

		--textColorSubMenuNavItem: var(--textColor);
		--backgroundColorSubMenuNavItemHover: #E4E4E4;

		--backgroundColorUser: var(--mainColor);
		--backgroundColorUserHover: var(--backgroundColorSubmitHover);
		--boxShadowUser: 0 0 3px 1px rgba(0, 0, 0, 0.2);

		--backgroundColorInput: #FFFFFF;
		--backgroundColorInputHover: #FAFAFA;
		--textColorInput: black;
		--borderInput: 1px solid #EBEBEB;
		--borderColorInputHover: #005F35;
		--borderColorInputText: rgb(140, 140, 140);
		--borderColorInputTextFocus: rgba(0, 40, 122, 0.98);
		--backgroundColorNoInputHover: #FAFAFA;
		
		--textColorButtonAccess: black;
		--borderButtonAccess: 1px solid var(--mainColorAlpha);
		--borderButtonAccessHover: 1px solid var(--mainColor);
		
		--backgroundColorBarStrengthLow: var(--errorColor);
		--backgroundColorBarStrengthMid: var(--warningColor);
		--backgroundColorBarStrengthStrong: var(--validColor);
		
		--backgroundColorListChoices: var(--backgroundColorInput);
		--backgroundColorListChoicesChoiceHover: var(--backgroundColorInputHover);
		--borderBottomListChoicesChoice: 1px solid var(--backgroundColor);

		--backgroundColorSubmit: #008C4F;
		--backgroundColorSubmitHover: #005F35;
		--textColorSubmit: white;

		--backgroundColorDelete: #C33030;
		--backgroundColorDeleteHover: #942121;
		--textColorDelete: white;

		--textColorLabel: rgb(100, 100, 100);
		--textColorLabelCheckbox: black;

		--borderTable: 1px solid #D2D2D2;
		--backgroundColorTRHover: rgba(250, 250, 250, 0.1);

        --borderTabsBar: 1px solid #D2D2D2;
        --borderTabsBarTab: 2px solid var(--mainColor);
        --borderTabsBarTabTransparent: 2px solid transparent;

		--backgroundColorBubbleInformation: var(--mainColor);
		--textColorBubbleInformation: white;
		
		--backgroundTrLineButton: var(--mainColor);
		--backgroundTrLineButtonHover: var(--backgroundColorSubmitHover);
	}
}
@media (prefers-color-scheme: dark) {
	:root {
		--mainColor: #008C4F;
		--mainColorAlpha: rgba(0, 140, 79, 0.5);
		--fontSizeInputText: 10pt;
		--borderRadiusInputText: 3px;
		--paddingInputText: 0 15px;
		--backgroundColor: #1D1D1D;
		--boxColor: #242424;
		--logo: url(../image/logo/hivy-logo-white.svg);
		--textColor: rgb(198, 198, 198);
		--errorColor: rgb(195, 48, 48);
		--warningColor: rgb(195, 134, 19);
		--validColor: var(--mainColor);

		--backgroundColorOptions: var(--boxColor);
		--borderColorOptions: 1px solid #323232;
		--boxShadowOptions: 0 3px 6px rgba(0, 0, 0, 0.3);
		--textColorOptionItems: var(--textColor);
		--backgroundColorOptionItemsHover: #383838;
		--textColorOptionItemsRed: rgb(195, 48, 48);
		--backgroundColorOptionItemsRed: rgba(195, 48, 48, 0.1);

		--backgroundColorMenu: var(--backgroundColor);
		--borderMenu: 1px solid #323232;
		--borderItemMenuSelected: 3px solid var(--mainColor);

		--textColorSubMenuNavItem: var(--textColor);
		--backgroundColorSubMenuNavItemHover: #131313;

		--backgroundColorUser: var(--mainColor);
		--backgroundColorUserHover: var(--backgroundColorSubmitHover);
		--boxShadowUser: 0 0 3px 1px rgba(0, 0, 0, 0.2);

		--backgroundColorInput: #323232;
		--backgroundColorInputHover: #3C3C3C;
		--textColorInput: white;
		--borderInput: 1px solid #1E1E1E;
		--borderColorInputHover: #005F35;
		--borderColorInputTextFocus: rgba(0, 40, 122, 0.98);
		--backgroundColorNoInputHover: #282828;
		--progressBarColorInputFile: var(--mainColor);
		
		--textColorButtonAccess: white;
		--borderButtonAccess: 1px solid var(--mainColorAlpha);
		--borderButtonAccessHover: 1px solid var(--mainColor);
		
		--backgroundColorBarStrengthLow: var(--errorColor);
		--backgroundColorBarStrengthMid: var(--warningColor);
		--backgroundColorBarStrengthStrong: var(--validColor);

        --backgroundColorListChoices: var(--backgroundColorInput);
        --backgroundColorListChoicesChoiceHover: var(--backgroundColorInputHover);
        --borderBottomListChoicesChoice: 1px solid var(--backgroundColor);

		--backgroundColorSubmit: #008C4F;
		--backgroundColorSubmitHover: #005F35;
		--textColorSubmit: white;

		--backgroundColorDelete: #C33030;
		--backgroundColorDeleteHover: #942121;
		--textColorDelete: white;

		--textColorLabel: rgb(198, 198, 198);
		--textColorLabelCheckbox: rgb(198, 198, 198);

		--borderTable: 1px solid #323232;
		--backgroundColorTRHover: rgba(60, 60, 60, 0.1);

        --borderTabsBar: 1px solid #323232;
        --borderTabsBarTab: 2px solid var(--mainColor);
        --borderTabsBarTabTransparent: 2px solid transparent;

		--backgroundColorBubbleInformation: var(--mainColor);
		--textColorBubbleInformation: white;
		
		--backgroundTrLineButton: var(--mainColor);
		--backgroundTrLineButtonHover: var(--backgroundColorSubmitHover);
		
		--colorBorderSetupInstallationPhase: rgba(150, 150, 150, 1);
		--colorBorderSetupInstallationLine: rgba(100, 100, 100, 1);
	}
}
