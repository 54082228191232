.subMenuNavItem {
	min-width: 180px;
	width: 180px;
	margin: 5px 10px;
	padding: 12px 15px 12px 13px;
	font-size: 10pt;
	text-align: left;
	text-transform: uppercase;
	border-radius: 3px;
	color: var(--textColorSubMenuNavItem);
	background-color: var(--backgroundColor);
	border-left: 2px solid var(--backgroundColor);
	cursor: pointer;
	transition: all 0.15s;
}
.subMenuNavItem.selected {
	background-color: var(--mainColor);
	border-left: 2px solid var(--mainColor);
	color: white;
}
.subMenuNavItem:not(.selected):hover {
	background-color: var(--backgroundColorSubMenuNavItemHover);
	border-left: 2px solid var(--mainColor);
}
