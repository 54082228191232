.item {
	width: 100%;
	height: 65px;
	margin: 0;
	cursor: pointer;
	transition: all 0.15s;
}
.item.selected {
	width: calc(100% - 2px);
	border-right: var(--borderItemMenuSelected);
}

.item svg {
	width: 21px;
	height: 21px;
	margin: 22px;
	transition: all 0.15s;
}
.item.selected svg {
	fill: #008C4F;
}
.item:hover svg {
	fill: #008C4F;
}
