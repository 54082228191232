.menu {
	position: fixed;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 11;
	width: 65px;
	height: 100vh;
	min-height: 100vh;
	max-height: 100vh;
	left: 0;
	top: 0;
	margin: 0;
	padding: 0;
	border-right: var(--borderMenu);
	background-color: var(--backgroundColorMenu);
}
